<template>
  <div
    class="header-search-box custom-scrollbar fancy-scroll"
    id="header-search-bar"
    :style="{ display: isActive }"
  >
    <span @click="closeModal" class="close-search">
      <font-awesome-icon icon="times"
    /></span>
    <div class="search-box-inner" v-if="searchSuggestions">
      <div class="serach-heading">
        <div class="serach-heading-title s-hd">
          <span><font-awesome-icon icon="caret-down" /></span>
          {{ $t("you_mean") }}:
        </div>
        <div class="search-header-link pb-10">
          <ul>
            <li v-for="(searchSuggestion, index) of suggestion" :key="index">
              <b-link
                @click="
                  $emit('handleSearch', '');
                  $router.push('/search/?q=' + searchSuggestion.word);
                  $root.$emit('closeSearchModal');
                "
                >{{ searchSuggestion.word }}
                <span v-if="searchSuggestion.count"
                  >({{ searchSuggestion.count }})</span
                >
              </b-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div
      class="search-box-inner"
      v-if="recommendedCategories && recommendedCategories.length"
    >
      <div class="serach-heading">
        <div class="serach-heading-title s-hd">
          <span><font-awesome-icon icon="caret-down" /></span>
          {{ $t("about_search_categories") }}
        </div>
        <div class="search-header-link pb-10">
          <ul>
            <li v-for="(category, index) of categories" :key="index">
              <b-link
                @click="
                  $emit('handleSearch', '');
                  $router.push('/' + category.url_path + '/');
                  $root.$emit('closeSearchModal');
                "
                :to="category.url_path + '/'"
                >{{ category.name }}
              </b-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="search-box-inner"
      v-if="recommendedBlogs && recommendedBlogs.length"
    >
      <div class="serach-heading">
        <div class="serach-heading-title s-hd">
          <span><font-awesome-icon icon="caret-down" /></span>
          {{ $t("about_search") }}:
        </div>
        <div class="search-header-link pb-10 px-30 mb-4 search-products">
          <VueSlickCarousel v-bind="settings_acc_slider">
            <b-link
              class="product_acc"
              v-for="(blog, idx) of blogs"
              :key="'blog' + idx"
              @click="$router.push('/')"
            >
              <b-card-body class="search-product-slider">
                <div class="search-product-slider-wrap">
                  <h5 class="search-product-slider-title">
                    {{ blog.title }}
                  </h5>

                  <div
                    class="search-product-slide-content"
                    v-html="blog.content"
                  ></div>
                </div>
              </b-card-body>
            </b-link>
          </VueSlickCarousel>
        </div>
      </div>
    </div>
    <div
      class="search-box-inner"
      v-if="recommendedProducts && recommendedProducts.length"
    >
      <div class="serach-heading">
        <div class="serach-heading-title s-hd">
          <span><font-awesome-icon icon="caret-down" /></span>
          {{ $t("product_you_search") }}:
        </div>
        <div>
          <button
            class="btn btn-success"
            @click="
              $emit('handleSearch', '');
              $router.push('/search/?q=' + searchText);
              $root.$emit('closeSearchModal');
            "
          >
            {{ $t("all_search_results") }}
          </button>
        </div>
        <div class="search-products-wrap">
          <VueSlickCarousel v-bind="settings_prod_slider">
            <div
              class="md-last-cd-none"
              v-for="recommendedProduct of recommendedProducts"
              :key="recommendedProduct.sku"
            >
              <ProductCard :product="recommendedProduct" />
            </div>
          </VueSlickCarousel>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import ProductCard from "@/esf_utrecht_bankxl/core/components/products/category-product/ProductCard.vue";
export default {
  name: "SearchComponent",
  components: {
    VueSlickCarousel,
    ProductCard,
  },
  props: {
    recommendedProducts: Array,
    recommendedCategories: Array,
    recommendedBlogs: Array,
    searchSuggestions: Array,
    searchText: String,
  },

  data() {
    return {
      isActive: "none",
      settings_acc_slider: {
        centerMode: false,
        dots: false,
        infinite: true,
        arrows: true,
        speed: 500,
        controls: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        adaptiveHeight: true,
        rtl: false,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 3,
              dots: false,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
        ],
      },
      settings_prod_slider: {
        centerMode: false,
        dots: false,
        infinite: true,
        arrows: true,
        slidesToShow: 5,
        slidesToScroll: 4,
        initialSlide: 0,
        adaptiveHeight: true,
        rtl: false,
        responsive: [
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.$root.$on("showSearchModal", this.showPopup);
    this.$root.$on("closeSearchModal", this.closeModal);

    document.addEventListener("click", this.close);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.close);
  },
  methods: {
    showPopup() {
      this.isActive = "block";
    },
    close(e) {
      if (!this.$el.parentElement.contains(e.target)) {
        this.closeModal();
      }
    },
    closeModal() {
      this.isActive = "none";
    },
    sortValues(data) {
      return data.sort((a, b) => {
        return a.name > b.name
          ? 1
          : b.name > a.name
          ? -1
          : a.path > b.path
          ? 1
          : b.path > a.path
          ? -1
          : 0;
      });
    },
  },
  computed: {
    suggestion() {
      return this.sortValues(this.searchSuggestions);
    },
    categories() {
      return this.sortValues(this.recommendedCategories);
    },
    blogs() {
      return this.sortValues(this.recommendedBlogs);
    },
  },
};
</script>

<style scoped>
.search-product-slide-content :not(:first-child) {
  display: none;
}
</style>
